import React, {Component} from 'react';
import {Redirect, withRouter} from 'react-router-dom'
import {API_ROOT, DOMAIN} from "../api-config";


class root1 extends Component {

    constructor(props, context) {
        super(props, context);
        console.log(DOMAIN);

        this.state = {
            redirect404: false,
            redirectadmission: false,
            redirectparty: false,

        };

    };


    componentDidMount() {
        sessionStorage.clear();

        //if (!localStorage.getItem('merchant_id')) {
            this.fetchMerchant();
        /*} else {

            if(localStorage.getItem("merchant_default_page")=="P"){
                this.setState({redirectparty:true});
            }else{
                this.setState({redirectadmission:true});
            }

        }*/
    }

    fetchMerchant = () => {

        var url = API_ROOT + '/fetchmerchant';

        this.setState({loading: true});
        fetch(url)
            .then(response => {
                return response.json();
            })
            .then(merchantData => {

                if (merchantData.status == 1 && merchantData.merchant_data.length > 0) {

                    localStorage.setItem('merchant_gateway_type', merchantData.merchant_data[0].merchant_gateway_type);

                    localStorage.setItem('merchant_id', merchantData.merchant_data[0].merchant_id);
                    localStorage.setItem('merchant_name', merchantData.merchant_data[0].merchant_name);
                    localStorage.setItem('merchant_image', merchantData.merchant_data[0].merchant_image);
                    localStorage.setItem('merchant_phone', merchantData.merchant_data[0].merchant_phone);
                    localStorage.setItem('merchant_default_page', merchantData.merchant_data[0].merchant_default_page);
                    localStorage.setItem('merchant_liability_waiver_enabled', merchantData.merchant_data[0].merchant_liability_waiver_enabled);
                    localStorage.setItem('merchant_liability_waiver_text', merchantData.merchant_data[0].merchant_liability_waiver_text);

                    if( merchantData.merchant_data[0].merchant_default_page=="P"){
                        this.setState({redirectparty:true});
                    }else{
                        this.setState({redirectadmission:true});
                    }


                } else {

                    this.setState({redirect: false});
                    this.setState({redirect404: true});
                }
            });
    };

    render() {
        if (this.state.redirect404) {
            return <Redirect to="/404"/>;
        }
        if (this.state.redirectparty) {
            return <Redirect to="/party"/>;
        }
        if (this.state.redirectadmission) {
            return <Redirect to="/admission"/>;
        }

        return ('');
    }
}

export default withRouter(root1);
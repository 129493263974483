let backendHost;
let imageHost;
let sub_domain;
const hostname = window && window.location && window.location.hostname;


imageHost = 'https://events.brytecommerce.com';
sub_domain = window.location.hostname;
if (hostname === 'localhost') {
    backendHost = 'http://localhost:3001';
    imageHost = backendHost;

}else{
    backendHost = 'https://'+hostname;
    imageHost=backendHost;
}

export const API_ROOT = `${backendHost}/webservices`;
export const IMAGE_ROOT = `${imageHost}`;
export const DOMAIN= `${hostname}`;